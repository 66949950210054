import { Route } from 'components/ui'
import { SeoTags } from 'components/seoTags'
import {
    JournalPage,
    FRONT_PAGE_KEY,
    JOURNAL_PAGES,
    getJournalPageUrl,
} from 'components/journal'

export default function Journal({ page = FRONT_PAGE_KEY }) {
    return (
        <Route>
            <SeoTags
                title={
                    JOURNAL_PAGES.find(p => p.key === page)?.seoTitle ??
                    'Journal'
                }
                description={
                    JOURNAL_PAGES.find(p => p.key === page)?.seoDescription ??
                    ''
                }
                canonicalPath={getJournalPageUrl(page)}
            />
            <JournalPage page={page} />
        </Route>
    )
}
