import { fetchStrapiContent } from 'data'
import { JOURNAL_PAGES } from 'components/journal'

export { default } from '../index'

export const getServerSideProps = async ({ params }) => {
    const { page } = params

    const categoryDoesExist = !!JOURNAL_PAGES.find(p => p.key === page)

    if (!categoryDoesExist) {
        // Handle legacy links:
        // If the category does not exist, check if it is a slug of an existing post
        const slug = page
        const response = await fetchStrapiContent(
            `slug: { eq: "${slug}" }`,
            0,
            1
        )
        let post = null
        if (response.ok) {
            const result = await response.json()
            post = result?.data?.posts?.data?.[0]?.attributes

            const category = JOURNAL_PAGES.find(
                p => p.name === post?.categories?.data?.[0]?.attributes?.name
            )?.key
            if (category) {
                // Permanent redirect to the journal post page
                return {
                    redirect: {
                        destination: `/journal/${category}/${slug}`,
                        permanent: true,
                    },
                }
            }
        }

        return {
            // Redirect to the front page if the post and category do not exist
            // TODO: 404 when the posts are added to the search?
            redirect: {
                destination: '/journal',
                permanent: false,
            },
        }
    }

    return {
        props: {
            page,
        },
    }
}
